.header1 {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}

.welcome-message h1 {
  margin: 0;
  color: #fff;
  font-weight: bolder;
}

.welcome-message p {
  color: #636e72;
}

.calendar {
  width: 300px;
}

.header1 {
  flex: 1;
  background-image: url("../Assets/Images/welcomebg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.welcome-message h1 {
  margin: 0;
  color: #fff;
  font-size: 2rem;
}

.welcome-message p {
  color: #000000;
  width: 65%;
  margin: 16px 0px;
}

.welcome-message button {
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #fcfcfc;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  margin-top: 10px;
  font-size: 16px;
  transition: all 0.25s ease-in-out;
}

.welcome-message button:hover {
  border: 2px solid transparent;
  background: #8949ff;
}

@media (max-width: 1125px) {
  .welcome-message h1 {
    font-size: 1.6rem;
  }

  .welcome-message p {
    margin: 8px 0px;
    font-size: 0.8rem;
  }

  .welcome-message button {
    padding: 10px 15px;
    border-radius: 7px;
    font-size: 0.8rem;
  }
}

@media (max-width: 835px) {
  .header {
    width: 100%;
    padding: 10px;
  }

  .welcome-message h1 {
    font-size: 0.85rem;
  }

  .welcome-message p {
    width: 80%;
    margin: 8px 0px;
    font-size: 0.6rem;
  }

  .welcome-message button {
    padding: 5px 10px;
    border-radius: 7px;
    margin-top: 3px;
    font-size: 0.6rem;
  }
}
