.navbar-nav li:hover .mega-box,
.mega-box:hover {
  transition: all 0.3s ease;
  opacity: 1;
  visibility: visible;
  /* animation: slideUpScale 1s ease forwards; */
}
/* 
@keyframes slideUpScale {
  from {
    opacity: 0;
    transform: translateY(100px) scale(0.1);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
} */
.collapse{
  min-height: 100%;
  height: max-content;
}
/* .navbar-nav{
  height: 100%;
} */
.navbar-nav li{
  height: 100%;
  display: flex;
  align-items: center;
}
.navbar-nav li:hover .angledown
{
  transition: all 0.3s ease;
  transform: rotate(180deg);
}

.mega-box {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 60vh;
  top: 90px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.mega-box .content {
  background: white;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.mega-box .content .rows-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width:40%;
  height: 100%;
  line-height: 45px;
}
.mega-box .content .rows {
  width:20%;
  height: 100%;
  line-height: 45px;
}

.content .rows header {
  font-size: 17px;
  padding-left: 2.5rem;
  font-weight: 500;
}

.content .rows .mega-links{
  border-left: 1px solid rgba(255, 255, 255, 0.09);
  list-style: none;
}

.rows .mega-links li a {
  font-size: 15px;
  display: block;
}

.rows .mega-links li a:hover {
  color: red;
  text-decoration: none;
}

@media (max-width: 840px) {
  .collapse{
    background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 20px 0px;
  }
  .mega-box .content {
    height: 100%;
    flex-direction: column;
  }
  .mega-box .content .rows {
    width: 100%;
    height: 20%;
  }
  .content .rows img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .mega-box .content .rows ul {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 710px) {
  .mega-box {
    height: max-content;
    min-height: 80vh;
    overflow: scroll;
  }
  .mega-box .content {
    flex-direction: column;
    padding-bottom: 100px;
    overflow: scroll;
  }
  .mega-box .content .rows {
    width: 100%;
    height: max-content;
  }
  .content .rows img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .mega-box .content .rows ul {
    display: grid;
    flex-direction: column;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 555px) {
  .mega-box .content{
    height: 100%;
  }
  .mega-box .content .rows ul {
    grid-template-columns: repeat(2, 1fr);
  }
}