.main-content {
  cursor: default;
  padding: 0px 20px 20px 20px;
}

.filterChips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.filterChip {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filterChip:hover {
  opacity: 0.9;
  scale: 1.03;
}

.filterChip.active {
  background-color: #6366f1;
  color: white;
}

.clearFilters {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  color: white;
  background-color: #ec2424;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clearFilters:hover {
  background-color: #d1d1d1;
}

/* Design 3 */
.cardContainer3 {
  padding: 20px;
}

.cardContainer3 h2 {
  padding: 10px;
  color: #8949ff;
  margin-bottom: 20px;
}

.cardContainer3 h3 {
  padding: 10px 20px;
  color: #8949ff;
  width: max-content;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: rgba(220, 220, 220, 0.707);
}

.courseContainer3 {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(4, 1fr);
}

.courseLessonBox3 h5 {
  color: white;
  border-radius: 10px;
  padding: 5px 10px;
  width: max-content;
  background-color: #8949ff;
}

.courseCard3 {
  height: 100%;
  display: flex;
  padding: 12px;
  cursor: default;
  overflow: hidden;
  position: relative;
  border-radius: 11px;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(198, 197, 197, 0.59);
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s ease-in-out;
}

.courseCard3:hover {
  background-color: rgb(189, 189, 189);
}

.courseOverlay3 {
  z-index: 1;
  height: 100%;
  position: relative;
  transform: translateY(0%);
  transition: all 0.4s ease-in-out;
}

.courseCard3:hover .courseOverlay3 {
  opacity: 0.4;
  transform: scale(0.93) translateY(1%);
}

.courseImageBox3 {
  position: relative;
}

.courseImage3 {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 16px;
}

.courseImageTxt3 {
  position: absolute;
  top: 55%;
  left: 10%;
  right: 0%;
  z-index: 2;
  color: white;
  font-size: medium;
  padding: 1% 3%;
  font-weight: 500;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: opacity 0.4s ease-in-out;
  background-color: rgba(63, 0, 179, 0.65);
}

.courseDetails3 {
  z-index: 2;
  display: flex;
  padding: 10px 0;
  min-height: 45%;
  flex-direction: column;
  justify-content: space-between;
  transition: opacity 0.4s ease-in-out;
}

.courseDetails3 p {
  font-size: 0.9em;
  font-weight: 500;
  margin: 3px 0 12px 3px;
}

.courseDetailBtn3,
.lessonDetailBtn3 {
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 9px 15px;
  font-size: 0.85em;
  border-radius: 6px;
  background-color: rgba(76, 3, 210, 1);
  transition: background-color 0.3s ease-in-out;
}

.courseDetailBtn3:hover,
.lessonDetailBtn3:hover {
  background-color: rgba(63, 0, 179, 0.85);
}

.courseLessonBox3 {
  position: absolute;
  color: white;
  top: 12%;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 88%;
  padding: 20px;
  display: flex;
  z-index: 3;
  opacity: 0;
  flex-direction: column;
  transform: translateY(100%);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: rgba(0, 0, 0, 0.85);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.courseLessonBox3 ul {
  padding: 0% 10px;
  font-size: 0.85em;
}

.courseCard3:hover .courseLessonBox3 {
  opacity: 1;
  transform: translateY(0);
}

.preview-page {
  width: 100%;
  display: flex;
  height: 100%;

}

.course-info-cnt {
  width: 100%;
  height: 100%;
  /* background: yellowgreen; */
  padding: 4rem 4rem;
}

.course-amount-cnt {
  width: 100%;
  height: 100%;
  padding: 4rem 4rem;
  position: relative;
  display: flex;
  align-items: center;
}

.course-amount-cnt-phone{
  display: none;
}

.course-title {
  font-size: 2rem;
  font-weight: 600;
}

.lesson-count-cnt {
  display: flex;
  gap: 0.8rem;
  margin-top: 1rem;
}

.lesson-icon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
}

.lessons-video-cnt {
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
  background: gray;
  margin-top: 2rem;
}

.course-about-cnt,
.course-outcome-cnt {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.85rem;
  padding: 2rem;
}

.preview-header {
  font-size: 1.5rem;
  font-weight: 600;
}

.more-btn {
  cursor: pointer;
  color: rgb(27, 88, 179);
  text-decoration: underline;
  margin-left: 0.5rem;
}

.outcome-list {
  display: flex;
  flex-direction: column;
  gap:1rem;
}

.outcome-item {
  display: flex;
  gap: 0.75rem;
}

.outcome-description {
  font-size: 0.9rem;
  color: gray;
}

.course-price-info-cnt {
  width: 100%;
  min-height: 20rem;
  border-left: 2px solid gray;
  padding: 1rem 2rem;
}

.text-icon {
  font-size: 1.4rem;
}

.enroll-btn {
  width: 25%;
  height: 2.75rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8949ff;
  margin-top: 1rem;
  cursor: pointer;
  margin-left: 30rem;
}

.white-text {
  font-size: 1.2rem;
  color: white;
}

.price-text{
  font-size: 1.5rem;
  font-weight: 600;
  color: #8949ff;
  margin-top: 1rem;
}

@media (max-width: 1125px) {
  .main-content {
    padding: 0px 5px 5px 5px;
  }

  .cardContainer3 h2,
  .cardContainer3 h3 {
    padding: 5px;
    font-size: large;
    margin-bottom: 10px;
  }

  .cardContainer3 h3 {
    font-size: medium;
  }

  .courseLessonBox3 h5 {
    font-size: medium;
    font-weight: 400;
  }

  .courseLessonBox3 ul {
    padding: 0px 5px 0px 10px;
    font-size: 0.7em;
  }

  .courseContainer3 {
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  .courseImage3 {
    height: 150px;
  }

  .courseImageTxt3 {
    font-size: x-small;
  }

  .courseDetails3 p {
    font-size: 0.7em;
  }

  .courseDetailBtn3,
  .lessonDetailBtn3 {
    padding: 8px 10px;
    font-size: 0.7em;
  }

  .filterChip,
  .clearFilters {
    padding: 4px 8px;
    font-size: 12px;
  }
}

@media (max-width: 835px) {
  .cardContainer3 {
    padding: 8px 2px;
  }

  .courseContainer3 {
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 550px) {
  .preview-page {
    padding: 1rem 1rem;
  }

  .course-info-cnt {
    width: 100%;
    padding: 0;
  }

  .course-amount-cnt {
    display: none;
  }

  .course-title {
    font-size: 1.5rem;
  }

  .course-about-cnt,
  .course-outcome-cnt {
    padding: 0.5rem;
  }

  .preview-header {
    font-size: 1.1rem;
  }

  .outcome-list p {
    font-size: 0.9rem;
  }

  .outcome-description {
    font-size: 0.7rem;
    color: rgba(128, 128, 128, 0.775);
  }

  .lessons-video-cnt {
    height: 10rem;
  }

  .course-amount-cnt-phone {
    width: 100%;
    height: fit-content;
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .course-price-info-cnt {
    padding: 1rem;
    border: none;
  }
}