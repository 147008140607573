@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+Display:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.entrylevel-head {
    /* background-image: url('../asset/background-image-entrylevel.jpg'); 
    background-repeat: no-repeat;
    background-size: 100% 100%; */
    background: 
    linear-gradient(135.38deg, #E6E6FF 66.5%, transparent 50%),
    linear-gradient(45.65deg,  #BF00FF 40.72%, #8949FF 128.96% , #0000FF 59.02%);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Be Vietnam Pro", sans-serif;
}

.entrylevel-inside {
    background-color:#E5EFFF;
    border-radius:10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.7);
    height: calc(100vh - 6rem);
    width: calc(100% - 15rem);
    max-width:100%;
    overflow: hidden;
}

.entrylevel-nav-content{
    height: 7%;
    background: #FFFFFF;
    padding:0.5rem;
    padding-left:60px;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    box-shadow: 0px 2px 10px 0px #00000026;
}

/* Ensure the parent container takes the full height of the viewport */
.parent-container {
    height: 93%;
    display: flex;
    justify-content: center;
    align-items: center;  
}

/* Style the card container */
.entryclass-card-container {
    background-color: rgb(255, 255, 255);
    height:100%;
    width:100%;
}

.ELA-heading{
    background: linear-gradient(91.24deg, #7F00FF 0%, #9735FA 98.76%);
    width: 100%;
    height:20%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.entryclass-card-container .row{
    height: 75%;
}

#ela-title-head{
    font-family: "Be Vietnam Pro", sans-serif;
    color: #FFFFFF;
    font-size: 38px;
    font-weight: 600;
}

#ela-sub-title{
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 31.63px;
    color: #FFFFFF;
}

.assessment-details, .assessment-overview{
    padding:4.5rem; 
}

.assessment-details h2, .assessment-overview h2 {
    margin-bottom: 20px; 
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 37.95px;
    text-align: left;
}

.assessment-details .span-para{
    padding-left:1rem;
}

.assessment-details .span-type{
    font-size:15px;
    font-weight:600;
    Line-height:43px;
    padding-left:1rem;
    color: #000000;   
}

.assessment-overview .first-para {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #666666;
}

.assessment-overview .number-questions{
    font-family: "Be Vietnam Pro", sans-serif;
    font-weight: 600;
    text-align: left;  
}

.assessment-overview .number-questions span{
    font-family: "Be Vietnam Pro", sans-serif;
    font-weight: 400;
}

.assessment-overview button {
    font-family: "Be Vietnam Pro", sans-serif;
    padding: 8px 25px;
    text-align: left; 
    font-weight: 600;   
    font-size: 16px;
    color: #ffffff;
    background: #7F00FF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Responsive Design */
@media only screen and (max-width: 1200px) {

    .entrylevel-nav-content{
        height: 10%;
    }
    .parent-container{
        height: 90%;
    }
    .entrylevel-head {
        height:auto;
    }


    .entrylevel-inside {
       min-height: calc(100vh - 2rem);
       height: calc(max-content - 2rem);
       margin: 1rem 0px;
    }

    .entryclass-card-container {
        width: 100%;
        height: 100%;
    }

    .entryclass-card-container .row{
        height: 80%;
        overflow: scroll;
    }

    .assessment-details, .assessment-overview {
        padding: 3rem;
    }
}

@media only screen and (max-width: 768px) {
    .entrylevel-nav-content{
        height: 7%;
    }
    .parent-container{
        height: 93%;
    }
    .entrylevel-head {
        height:auto;
    }


    .entrylevel-inside {
       height: calc(150vh - 2rem);
    }

    .entryclass-card-container {
        width: 100%;
        height: 100%;
    }

    .entryclass-card-container .ELA-heading{
        height: 15%;
    }

    .entryclass-card-container .row{
        height: 85%;
    }

    .assessment-details, .assessment-overview {
        padding: 2rem;
    }

    #ela-title-head {
        font-size: 30px;
    }

    #ela-sub-title {
        font-size: 16px;
    }

    .assessment-details h2, .assessment-overview h2 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {

    .entrylevel-head {
        height:auto;
    }


    .entrylevel-inside {
       height: calc(150vh - 2rem);
    }

    .entryclass-card-container {
        width: 100%;
        height: 100%;
    }

    .assessment-details, .assessment-overview {
        padding: 1.5rem;
    }

    #ela-title-head {
        font-size: 20px;
    }

    #ela-sub-title {
        font-size:14px;
    }

    .assessment-details h2, .assessment-overview h2 {
        font-size: 18px;
    }

    .assessment-overview .first-para {
        font-size: 14px;
    }

    .assessment-overview button {
        font-size: 14px;
    }
}
