.mainContent {
  width: 100%;
  padding: 20px;
  overflow: hidden;
  min-height: 100vh;
}

.headerPart {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.home-courseBox {
  margin-top: 20px;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.home-courseBox h3 {
  color: #2c3e50;
  display: block;
  font-size: 1.17em;
  font-weight: bold;
  unicode-bidi: isolate;
}

.home-course {
  display: flex;
  gap: 50px;
  max-width: 89.5vw;
  margin-top: 10px;
  overflow-x: scroll;
  place-items: center;
}

@media (max-width: 1125px) {
  .home-courseBox {
    padding: 15px;
  }

  .home-course {
    max-width: 85vw;
  }
}

@media (max-width: 835px) {
  .mainContent {
    padding: 10px;
  }

  .headerPart {
    flex-direction: column;
  }

  .home-courseBox {
    padding: 10px 15px;
  }

  .home-course {
    gap: 20px;
    max-width: 69vw;
  }

  .home-courseBox h3 {
    font-size: 1em;
  }
}
