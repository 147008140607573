@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

*{
    font-family: poppins;
    scrollbar-width: none;
}
html {
    scroll-behavior: smooth;
  }

  :root {
    --primary-color: #8949ff;
    --primary-light: #a86aff;
    --primary-dark: #5f32b8;
    --primary-bg: #f3e6ff;
    --shadow-color: rgba(0, 0, 0, 0.2);
    --shadow-light: rgba(0, 0, 0, 0.1);
  }
  