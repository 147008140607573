.Authentication main {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    /* background-color: #ff8c6b;
    background-image: url('./Assets/bg.jpg');
    background-repeat: no-repeat;
    background-size:cover; */
    background: 
    linear-gradient(135.38deg, #E6E6FF 66.5%, transparent 50%),
    linear-gradient(45.65deg,  #BF00FF 40.72%, #8949FF 128.96% , #0000FF 59.02%);
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Authentication main .box {
    position: relative;
    width: 100%;
    max-width: 80%;
    height: 640px;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
  }
  
  .Authentication main .box .inner-box {
    position: absolute;
    width: calc(100% - 4.1rem);
    height: calc(100% - 4.1rem);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .Authentication main .box .inner-box .forms-wrap {
    position: absolute;
    height: 100%;
    width: 45%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    transition: 0.8s ease-in-out;
  }
  
  .Authentication main .box .inner-box .forms-wrap form {
    max-width:360px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    transition: opacity 0.02s 0.4s;
  }
  
  .Authentication main .box .inner-box .forms-wrap form.sign-up-form {
    opacity: 0;
    pointer-events: none;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .logo img {
    width: 50%;
    margin-right: 0.3rem;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .logo h4 {
    font-size: 1.1rem;
    margin-top: -9px;
    letter-spacing: -0.5px;
    color: #151111;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .heading h2 {
    font-size: 2.1rem;
    font-weight: 600;
    color: #151111;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .heading h6 {
    color: #bababa;
    font-weight: 400;
    font-size: .95rem;
    display: inline;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .heading .toggle {
    color: #151111;
    text-decoration: none;
    font-size: .95rem;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .heading .toggle:hover {
    color: #8949FF;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .input-wrap {
    position: relative;
    height: 37px;
    margin-bottom: 2rem;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .input-wrap .input-field {
    position: absolute;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    border-bottom: 1px solid #bbb;
    padding: 0;
    font-size: 0.95rem;
    color: #151111;
    transition: 0.4s;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .input-wrap label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.95rem;
    color: #bbb;
    pointer-events: none;
    transition: 0.4s;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .input-wrap .input-field.active {
    border-bottom-color: #151111;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .input-wrap .input-field.active + label {
    font-size: 0.75rem;
    top: -2px;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .sign-btn {
    display: inline-block;
    width: 100%;
    height: 43px;
    background-color: #151111;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 0.8rem;
    font-size: 0.8rem;
    margin-bottom: 2rem;
    transition: 0.3s;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .sign-btn:hover {
    background-color: #8949FF;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .text {
    color: #bbb;
    font-size: .8rem;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .text a {
    color: #bbb;
    transition: 0.3s;
  }
  
  .Authentication main .box .inner-box .forms-wrap form .actual-form .text a:hover {
    color: #8949FF;
  }
  
  .Authentication .sign-up-mode form.sign-in-form {
    opacity: 0 !important;
    pointer-events: none !important;
  }
  
  .Authentication .sign-up-mode form.sign-up-form {
    opacity: 1 !important;
    pointer-events: all !important;
  }
  
  .Authentication .sign-up-mode .forms-wrap {
    left: 55% !important;
  }
  
  .Authentication .sign-up-mode .carousel {
    left: 0% !important;
  }
  
  .Authentication main .box .inner-box .carousel {
    position: absolute;
    height: 100%;
    width: 55%;
    left: 45%;
    top: 0;
    background-color: #ffe0d2;
    border-radius: 2rem;
    display: grid;
    grid-template-rows: auto 1fr;
    padding-bottom: 2rem;
    overflow: hidden;
    transition: 0.8s ease-in-out;
  }
  
  .Authentication main .box .inner-box .carousel .images-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  
  .Authentication main .box .inner-box .carousel .images-wrapper .image {
    width: 100%;
    grid-column: 1/2;
    grid-row: 1/2;
    opacity: 0;
    transition: opacity 0.3s, transform 0.5s;
  }
  
  .Authentication main .box .inner-box .carousel .images-wrapper .img-1 {
    transform: translate(0, -50px);
  }
  
  .Authentication main .box .inner-box .carousel .images-wrapper .img-2 {
    transform: scale(0.4, 0.5);
  }
  
  .Authentication main .box .inner-box .carousel .images-wrapper .img-3 {
    transform: scale(0.3) rotate(-20deg);
  }
  
  .Authentication main .box .inner-box .carousel .images-wrapper .image.show {
    opacity: 1;
    transform: none;
  }
  
  .Authentication main .box .inner-box .text-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .Authentication main .box .inner-box .text-slider .text-wrap {
    max-height: 2.2rem;
    overflow: hidden;
    margin-bottom: 1rem;
  }
  
  .Authentication main .box .inner-box .text-slider .text-wrap .text-group {
    display: flex;
    flex-direction: column;
    text-align: center;
    transform: translateY(0);
    transition: 0.5s;
  }
  
  .Authentication main .box .inner-box .text-slider .text-wrap .text-group h2 {
    line-height: 2.2rem;
    font-weight: 600;
    font-size: 1.6rem;
  }
  
  .Authentication main .box .inner-box .text-slider .bullets {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Authentication main .box .inner-box .text-slider .bullets span {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #aaa;
    margin: 0 0.25rem;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .Authentication main .box .inner-box .text-slider .bullets span.active {
    width: 1.1rem;
    background-color: #151111;
    border-radius: 1rem;
  }
  
  @media (max-width: 850px) {
    .Authentication main .box {
      height: auto;
      max-width: 550px;
      overflow: hidden;
    }
  
    .Authentication main .box .inner-box {
      position: static;
      transform: none;
      width: revert;
      height: revert;
      padding: 2rem;
    }
  
    .Authentication main .box .inner-box .forms-wrap {
      position: revert;
      width: 100%;
      height: auto;
    }
  
    .Authentication main .box .inner-box .forms-wrap form {
      max-width: revert;
      padding: 1.5rem 2.5rem 2rem;
      transition: transform 0.8s ease-in-out, opacity 0.45s linear;
    }
  
    .Authentication main .box .inner-box .forms-wrap form .heading {
      margin: 2rem 0;
    }
  
    .Authentication main .box .inner-box .forms-wrap form.sign-up-form {
      transform: translateX(100%) ;
    }
  
    .Authentication main.sign-up-mode form.sign-in-form {
      transform: translateX(-100%) !important;
    }
  
    .Authentication main.sign-up-mode form.sign-up-form {
      transform: translateX(0%) !important;
    }
  
    .Authentication main .box .inner-box .carousel {
      position: revert;
      height: auto;
      width: 100%;
      padding: 3rem 2rem;
      display: flex;
    }
  
    .Authentication main .box .inner-box .carousel .images-wrapper {
      display: none;
    }
  
    .Authentication main .box .inner-box .carousel .text-slider {
      width: 100%;
    }
  }
  
  @media (max-width: 530px) {
    .Authentication main {
      padding: 1rem;
    }
  
    .Authentication main .box {
      border-radius: 2rem;
    }
  
    .Authentication main .box .inner-box {
      padding: 1rem;
    }
  
    .Authentication main .box .inner-box .carousel {
      padding: 1.5rem 1rem;
      border-radius: 1.6rem;
    }
  
    .Authentication main .box .inner-box .carousel .text-slider .text-wrap {
      margin-bottom: 1rem;
    }
  
    .Authentication main .box .inner-box .carousel .text-slider .text-wrap .text-group h2 {
      font-size: 1.2rem;
    }
  
    .Authentication main .box .inner-box .forms-wrap  {
      padding: 1rem 2rem 1.5rem;
    }
  }
  