.error-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  padding: 20px;
}

.error-message {
  font-size: 1.5em;
  color: #d9534f; /* Bootstrap's danger color */
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.error-button,
.reload-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #5bc0de; /* Bootstrap's info color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.error-button:hover,
.reload-button:hover {
  background-color: #31b0d5; /* Bootstrap's info hover color */
}

@media (max-width: 1125px) {
  .error-message {
    font-size: 1.2em;
  }

  .error-button,
  .reload-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}
