.statistics {
  display: flex;
  gap: 20px;
  width: 100%;
}

.time-spent,
.completion {
  width: 50%;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.time-spent:hover,
.completion:hover {
  transform: translateY(-5px);
}

.time-spent h3,
.completion h3 {
  color: #2c3e50;
  margin-bottom: 10px;
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.chart-container {
  /* height: 300px; */
  position: relative;
  padding: 5px 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .time-spent .chart-container {
  background: linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%);
}

.completion .chart-container {
  background: linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%);
} */

.chart-title {
  font-size: 16px;
  color: #2c3e50;
  margin-bottom: 5px;
}

.chart {
  /* margin-top: 20px; */
  width: 100%;
  height: 100%;
}

.chart-legend {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.chart-legend div {
  display: flex;
  align-items: center;
}

.chart-legend span {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
}

.legend-sunday {
  background-color: #ff6384;
}
.legend-monday {
  background-color: #36a2eb;
}
.legend-tuesday {
  background-color: #ffcd56;
}
.legend-wednesday {
  background-color: #4bc0c0;
}
.legend-thursday {
  background-color: #9966ff;
}
.legend-friday {
  background-color: #ff9f40;
}
.legend-saturday {
  background-color: #c9cbcf;
}

.legend-completed {
  background-color: #28a745;
}
.legend-in-progress {
  background-color: #ffc107;
}
.legend-not-started {
  background-color: #dc3545;
}

@media (max-width: 1125px) {
  .time-spent,
  .completion {
    padding: 5px 10px;
  }

  .chart-legend {
    display: none;
  }

  .recharts-cartesian-axis-tick-value tspan {
    font-size: 13px;
  }

  .recharts-legend-item-text {
    font-size: 14px;
  }
}

@media (max-width: 835px) {
  .statistics {
    flex-direction: column;
  }

  .time-spent,
  .completion {
    padding: 5px 10px;
    width: 78vw;
  }

  .chart-legend {
    display: none;
  }

  .chart-title {
    font-size: 13px;
  }

  .recharts-cartesian-axis-tick-value tspan {
    font-size: 9px;
  }

  .recharts-legend-item-text {
    font-size: 13px;
  }

  .time-spent h3,
  .completion h3 {
    font-size: 1em;
  }

  .recharts-wrapper {
    max-width: 250px !important;
  }
}
