.ela-test-page {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  justify-self: end;
  padding: 1rem;
}

.ela-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #8949ff;
}

.ela-question-input-cnt {
  width: 100%;
  min-height: 30rem;
  height: fit-content;
}

.ela-question-input {
  min-height: 10rem;
}

.ela-choice-input-cover {
  margin-top: 2rem;
  height: fit-content;
}

.ela-choice-cnt {
  width: 100%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
}

.ela-inputs-cnt {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  /* padding: 1rem; */
}

.ela-choice {
  width: 100%;
}

.ela-question-info-cnt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: 500;
  padding-top: 1rem;
  border-top: 1px solid rgba(128, 128, 128, 0.47);
}

.ela-description {
  height: 6rem;
}
.ela-tags {
  height: 3rem;
}

.ela-dropdown-box {
  position: relative;
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.ela-dropdown-cnt {
  position: absolute;
  width: 100%;
  height: 12rem;
  top: -12.5rem;
  left: 0;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #bababa;
}

.ela-dropdown-element {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: #e7e7e7;
  cursor: pointer;
}

.ela-timer-input-cnt {
  width: 100%;
  height: 3rem;
  display: flex;
  gap: 2rem;
}

.ela-timer-input {
  width: 5rem;
  height: 2.5rem;
  text-align: center;
  font-weight: 500;
  color: #8949ff;
  font-size: 1.5rem;
}

.ela-timer-cover {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.justify-section-cnt {
  min-height: 10rem;
  overflow-x: scroll;
}

.section-indicator {
  width: fit-content;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #8949ff67;
  padding-right: 0.2rem;
}

.section-indicator p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-weight: 500;
  color: #ffffff;
}

.section-cnt {
  /* width: 30%; */
  flex: 1;
  min-width: 10rem;
  min-height: 100%;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  flex-wrap: wrap;
  /* background: #e8d2ff; */
  background: #d1d1d1;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(205, 205, 205);
  }
}

.questions-block-cover {
  flex: 1;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.ela-update-btn {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.5rem;
  background: #8949ff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 5px 1px #aa84ef;
  }
}
.ela-actions-btn-cnt {
  width: 100%;
}

.ela-new-section-btn {
  min-width: 10rem;
  flex: 1;
  min-height: 5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: gray;
  &:hover {
    background: #8949ff;
  }
}

.new-section-btn-text {
  /* writing-mode: vertical-rl; */
  /* transform: rotate(180deg); */
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}

@media (min-width: 700px) {
  .ela-inputs-cnt {
    padding: 0.5rem;
    margin-top: 0;
  }
  .ela-choice {
    width: 20rem;
  }
  .ela-question-input-cnt {
    width: 60%;
    min-height: 30rem;
    height: fit-content;
  }
  .ela-question-info-cnt {
    width: 35%;
    padding-top: 0;
    border: none;
  }
  .ela-choice-cnt {
    padding: 1rem;
  }
  .ela-dropdown-cnt {
    bottom: -12.5rem;
  }
  .ela-new-section-btn {
    display: flex;
    max-width: 10rem;
    /* width: 5rem; */
    flex: 1;

  }

  .new-section-btn-text {
    font-size: 1.2rem;
  }
}

@media (min-width: 1000px) {
  .ela-inputs-cnt {
    padding: 1rem;
  }
  .ela-test-page {
    width: calc(100% - 200px);
  }
  .ela-actions-btn-cnt {
    width: 90%;
    justify-content: space-around;
  }
}
