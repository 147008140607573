.center-all{
   display: flex;
   justify-content: center;
   align-items: center;
}

.content-head{ 
    margin:5rem;
    margin-top:6rem;
    margin-left:10rem;
    align-items: center;
}

/* Adjustments for very small devices */
@media (max-width: 768px) {
    .content-head{ 
        margin:0rem;
        margin-top:0rem;
        margin-left:0rem;
        align-items: center;
    }
}


.content-head h2{
  font-weight: bold; 
  font-size:2rem; 
  margin-bottom:15px;  
  /* background: linear-gradient(232deg, #02e1e1, #41d271, #c6d94b); */
  background: linear-gradient(135deg, #bf00ff, #8949ff, blue);
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-clip: text;
}

.content-head p{
    font-size:17px;
    font-weight: 600;
    margin-top: 20px;
}

.outer-image{
    background-image: url('./Assets/outer.png');
    background-repeat: no-repeat;
    background-size:100% 100%; 
    height:300px;
    width:400px;
}

.inner-image{
    height: 100%;
    margin-left:40px;
}