.cr-card {
  width: 200px;
  min-width: 200px;
  border-radius: 15px;
  margin-bottom: 20px;
  background-color: rgb(211, 210, 210);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.1);
}

.cr-image-container {
  margin: 3px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.cr-image {
  height: 160px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.cr-title {
  position: absolute;
  top: 55%;
  left: 5%;
  right: 0%;
  color: white;
  font-size: 1em;
  padding: 1% 3%;
  font-weight: 500;
  line-height: 1.2em;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: rgba(63, 0, 179, 0.65);
}

.cr-content {
  padding: 0 8px;
}

.cr-description {
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cr-button {
  color: #fff;
  border: none;
  display: grid;
  place-content: center;
  text-align: center;
  padding: 8px 12px;
  font-size: 0.85em;
  position: relative;
  transition: all 250ms;
  margin: 0 auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgba(76, 3, 210, 1);
  transition: all 0.3s ease-in-out;
}

.cr-button::before {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: all 250ms;
  background-color: var(--primary-color);
}

.cr-button:hover {
  transform: translateY(0px);
  background-color: rgba(63, 0, 179, 0.85);
  box-shadow: 0px 6px 8px var(--shadow-light);
}

.cr-button:hover::before {
  height: 100%;
}

@media (max-width: 1125px) {
  .cr-card {
    width: 150px;
    min-width: 150px;
  }

  .cr-image {
    height: 130px;
  }

  .cr-title {
    top: 55%;
    right: 0%;
    padding: 4% 3%;
    font-size: 0.7em;
  }

  .cr-button {
    font-size: 0.7em;
    padding: 8px 10px;
  }
}

@media (max-width: 835px) {
  .cr-card {
    width: 150px;
    min-width: 150px;
  }

  .cr-image {
    height: 130px;
  }

  .cr-title {
    top: 55%;
    right: 0%;
    padding: 4% 3%;
    font-size: 0.65em;
  }

  .cr-button {
    font-size: 0.6em;
    padding: 6px 10px;
  }
}
